import React from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const SpeechToText = () => {
    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' })
    }
    const { transcript, browserSupportsSpeechRecognition, listening } = useSpeechRecognition()
    if (!browserSupportsSpeechRecognition) {
        return null
    }
    const handleStop=()=>{
        SpeechRecognition.stopListening()
    }
    

    return (
        <>
            <div className="mx-auto flex flex-col justify-center items-center">

                <h3 className='text-center font-semibold text-2xl mb-6'>Speech To Text Test</h3>
                <p className='border w-[650px] mx-auto'>{transcript}  hhjgh</p>
                <p>Microphone: {listening ? 'on' : 'off'}</p>
                <div className='mt-8'>

                    <button className='border border-black px-6 mx-2' onClick={startListening}>Start</button>
                    <button className='border border-black px-6 mx-2' onClick={handleStop}>Stop</button>
                </div>
            </div>


        </>
    )
}

export default SpeechToText