import React, { useState } from 'react'
import Card from '../components/UI/Card'
import { useNavigate } from 'react-router-dom';
import PopupForm from './PopupForm'
import DataTable from './DataTable'

const LicenceManagement = () => {
    const navigate = useNavigate()
    const [dataTable, setDataTable] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const openForm = () => {
        setIsFormOpen(true);
    };

    const closeForm = () => {
        setIsFormOpen(false);
    };

    const handleFormSubmit = (formData) => {
        setDataTable([...dataTable, formData]);
        closeForm();
    };


    const handleDelete = (ind) => {
        const newArray = [...dataTable]; // Create a shallow copy of the original array
        newArray.splice(ind, 1); // Remove the element at the specified index
        setDataTable(newArray); // Update the state with the modified array
    }

    const hanldePlan = () => {
        navigate('/upgradeplan')
    }

    return (
        <>
            <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex">

                <div className='container box-lic lg:min-w-[300px] md:min-w-[250px] sm:min-w-[200px]'>
                    <ul className='text-center text-white text-2xl'>
                        <li className='pb-3'>Setting</li>
                        <li className='py-3'>Account</li>
                        <li className='py-3'>Licence Management</li>
                    </ul>
                </div>
                <Card className="bg-white w-6/12 h-4/5 rounded mt-5">
                    <div className='flex justify-between m-10'>
                        <h3 className='text-2xl font-semibold'>Licence Management</h3>
                        <div>
                            <button className='bg-green-500 text-white px-3 py-2 font-semibold rounded mx-3' onClick={hanldePlan}>Upgrade Plan</button>
                            <button className='bg-indigo-800 text-white px-3 py-2 font-semibold rounded' onClick={openForm}>Invite</button>
                        </div>
                    </div>
                    <div className="m-10 table-wrapper">
                        <DataTable data={dataTable} handleDelete={handleDelete} />

                    </div>
                </Card>
            </div>

            {/* <PopupForm/> */}
            {isFormOpen && <PopupForm onSubmit={handleFormSubmit} closeForm={closeForm} />}

        </>
    )
}

export default LicenceManagement 