import React, { useState } from 'react'
import Card from "../UI/Card";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { signupVal } from '../../Validation/Validation';
import { auth } from '../../utils/firebase.utils';
import { createUserWithEmailAndPassword } from 'firebase/auth';

function SignUp() {
    const navigate = useNavigate()
    const [error, setError] = useState(null)
    const [passwordType, setPasswordType] = useState("password");
    const [conPasswordType, setConPasswordType] = useState("password");
    const initialValues = {
        username: "",
        password: "",
        conPassword: ""
    }
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signupVal,
        onSubmit: (values) => {
            console.log(values)
            singup()
        }
    });

    async function singup(){
        await createUserWithEmailAndPassword(auth, values.username, values.password).then((userCredential)=>{
            console.log(userCredential)
            navigate('/login')
        }).catch((error)=>{
            console.log(error)
            if (error.code == "auth/email-already-in-use") {
                setError("The email address is already in use");
              } else if (error.code == "auth/invalid-email") {
                setError("The email address is not valid."); 
              } else if (error.code == "auth/operation-not-allowed") {
                setError("Operation not allowed.");
              } else if (error.code == "auth/weak-password") {
                  setError("The password is too weak.");
              }
        })
    }

    const toggleVisibility =(type,setType)=>{
        if(type==="password"){
            setType("text")
        }else{
            setType("password")
        }
    }
    const togglePasswordVisibility = () => toggleVisibility(passwordType, setPasswordType);
    const toggleConfirmPasswordVisibility = () => toggleVisibility(conPasswordType, setConPasswordType);

    
    return (
        <div className="login-wrapper h-screen bg-no-repeat login-grad relative  bg-center flex justify-center items-center">
            <div className="box-2 absolute">
                <div className="box-2-heading text-white">
                    <h1 className="kitchen-heading text-white">INTELLIGENT <br />SOFTWARE, <br />EXCEPTIONAL <br />SPACES</h1>
                    <div className="down-r box-2-p">
                        <p>with help of VERA AI</p>
                    </div>
                </div>
            </div>
            <Card className="card z-10">
                <form onSubmit={handleSubmit}>
                    <h1>Create your account</h1>
                    <div className="username pt-9">
                        <label htmlFor="username" className="font-medium">
                            Username
                        </label>
                        <Input
                            type="text"
                            name="username"
                            className="user1"
                            placeholder="Enter your username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className="error">
                            {error}
                        </div>
                        <span className="error">
                            {errors.username && touched.username && errors.username}
                        </span>
                    </div>
                    <div className="password py-6 relative">
                        <label htmlFor="password" className="font-medium">
                            Password
                        </label>
                        <Input
                            type={passwordType}
                            name="password"
                            className="pass"
                            placeholder="Enter your password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span className="error">
                            {errors.password && touched.password && errors.password}
                        </span>
                        <span onClick={togglePasswordVisibility} className="icon" >
                            {passwordType === "password" ? <VisibilityOffIcon className="svgicon" /> : <VisibilityIcon className="svgicon" />}
                        </span>
                    </div>
                    <div className="password mb-6 relative">
                        <label htmlFor="password" className="font-medium">
                            Confirm Password
                        </label>
                        <Input
                            type={conPasswordType}
                            name="conPassword"
                            className="pass"
                            placeholder="Enter your confirm password"
                            value={values.conPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span className="error">
                            {errors.conPassword && touched.conPassword && errors.conPassword}
                        </span>
                        <div onClick={toggleConfirmPasswordVisibility} className="icon" >
                            {conPasswordType === "password" ? <VisibilityOffIcon className="svgicon" /> : <VisibilityIcon className="svgicon" />}
                        </div>
                    </div>
                    <div className="flex justify-between pb-5">
                        <div className="check1 terms">
                            <Input type="checkbox" name="check" />
                            <p className="text-center ml-1">
                                By Singning Up I Agree with <Link className="font-medium text-blue-800">Terms & Conditions</Link>
                            </p>
                        </div>
                    </div>
                    <Button className="primary-btn ">Sign Up</Button>
                    <p className="text-center pt-5">
                        Already have an account? <Link to="/login" className="font-medium text-blue-800">Log In</Link>
                    </p>
                </form>
            </Card>
        </div>
    )
}

export default SignUp