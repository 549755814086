import React from 'react'
import Card from '../components/UI/Card'
import KitchenImg1 from '../assets/images/ideare-kitchen-1.jpg'
import KitchenImg2 from '../assets/images/ideare-kitchen-2.jpg'
import KitchenImg3 from '../assets/images/ideare-kitchen-3.jpg'
import LeftIcon from '../assets/images/left.png'
import RightIcon from '../assets/images/right.png'


import { Link } from 'react-router-dom'

const SelectDesign = () => {
    return (
        <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex justify-center items-center">
            <Card className="box-cus">
                <h2 className='image-p font-sans mb-2'>PLEASE SELECT PREFERRED DESIGN</h2>

                <div className="flex flex-row">
                    <Link to='/cabinet'>
                        <div className="flex flex-col mx-2">
                            <img src={KitchenImg1} alt="" />
                            <h5 className='image-desc font-sans mb-2 ml-3 mt-4'>Sustainabiliy Score</h5>
                            <div className="counter-bar mx-3 rounded-3xl">
                                <span className="score1 counter-percent rounded-3xl rounded-r-none"></span>
                            </div>
                        </div>
                    </Link>
                    <div className="flex flex-col mx-2">
                        <img src={KitchenImg2} alt="" />
                        <h5 className='image-desc font-sans mb-2 ml-2 mt-4'>Sustainabiliy Score</h5>
                        <div className="counter-bar mx-3 rounded-3xl">
                            <span className="score2 counter-percent rounded-3xl rounded-r-none"></span>
                        </div>
                    </div>

                    <div className="flex flex-col mx-2">
                        <img src={KitchenImg3} alt="" />
                        <h5 className='image-desc font-sans mb-2 ml-2 mt-4'>Sustainabiliy Score</h5>
                        <div className="counter-bar mx-3 rounded-3xl">
                            <span className="score3 counter-percent rounded-3xl rounded-r-none"></span>
                        </div>
                    </div>

                </div>
                <div className='iconsvg pt-3 mt-5'>
                    <Link to="/appliances"><img src={LeftIcon} alt='img' className='arrow-img' /></Link>
                    <Link to='/cabinet'> <img src={RightIcon} alt='img' className='arrow-img' /></Link>
                </div>
            </Card>
        </div>
    )
}

export default SelectDesign
