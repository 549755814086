import React, { useEffect, useState } from 'react'
import Logo from '../assets/images/IDEARE final.png'
import Button from '../components/UI/Button'
import PrintIcon from '../assets/images/print-icon.svg'


const ScopeOfWork = (props) => {
    const [totalAmount, setTotalAmount] = useState(0);
    // Update totalAmount whenever amount values change
    useEffect(() => {
        let sum = 0;
        for (const objKey in props.formdata) {
            if (props.formdata[objKey]?.amount) {
                sum += parseFloat(props.formdata[objKey].amount) || 0;
            }
        }
        setTotalAmount(sum);
    }, [])

    const currentDate = useState(new Date().toLocaleDateString());

    return (
        <>
            <div className="scope-container py-10 flex justify-center">
                <div className="w-[1624px] border px-10 py-12">
                    <div className="flex justify-between items-center mb-9">
                        <img src={Logo} alt="" className='w-32' />
                        <h5 className='text-4xl font-medium'>Bill of Materials</h5>
                    </div>
                    <hr className='hr-scope' />
                    <div className="scope-date font-semibold my-5">
                        Date: <span className='font-normal'>{currentDate}</span>
                    </div>
                    <div className="table-scope">
                        <table class="table-auto w-full font-medium">
                            <thead className='border bg-green-50'>
                                <tr>
                                    <th className='text-left py-5 px-10 w-[450px]'>Material</th>
                                    <th className='text-center py-5 px-3 w-[310px]'>Quantity</th>
                                    <th className='text-center py-5 px-3 w-[400px]'>Rate</th>
                                    <th className='text-center py-5 px-3'>Amount</th>
                                </tr>
                            </thead>
                            <tbody className='border'>
                                <tr className={`border ${props?.formdata?.obj?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj?.amount}</td>
                                </tr>
                                <tr className={`border ${props?.formdata?.obj2?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj2?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj2?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj2?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj2?.amount}</td>
                                </tr>
                                <tr>
                                    <td className='font-semibold py-3 px-10'>Backsplash Material</td>
                                </tr>
                                <tr className={`border ${props?.formdata?.obj3?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj3?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj3?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj3?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj3?.amount}</td>
                                </tr>
                                <tr className='border'>
                                    <td className='font-semibold py-3 px-10'>Flooring Material</td>
                                </tr>
                                <tr className={`${props?.formdata?.obj4?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj4?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj4?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj4?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj4?.amount}</td>
                                </tr>
                                <tr className={`${props?.formdata?.obj5?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj5?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj5?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj5?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj5?.amount}</td>
                                </tr>
                                <tr className={`${props?.formdata?.obj6?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj6?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj6?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj6?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj6?.amount}</td>
                                </tr>
                                <tr className={`${props?.formdata?.obj7?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj7?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj7?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj7?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj7?.amount}</td>
                                </tr>

                            </tbody>
                        </table>
                        <table class="table-auto w-full my-10 font-medium">
                            <thead className='border bg-green-50'>
                                <tr>
                                    <th className='text-left py-5 px-10 w-[450px]'>Work Description</th>
                                    <th className='text-center py-5 px-3'>Hours</th>
                                    <th className='text-center py-5 px-3'>Rate</th>
                                    <th className='text-center py-5 px-3'>Amount</th>
                                </tr>
                            </thead>
                            <tbody className='border'>
                                <tr className={`border ${props?.formdata?.obj8?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj8?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj8?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj8?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj8?.amount}</td>
                                </tr>
                                <tr className={`border ${props?.formdata?.obj9?.show === true ? "" : "hidden"}`}>
                                    <td className=' py-5 px-10'>{props?.formdata?.obj9?.name}</td>
                                    <td className='text-center py-5 px-3'>{props.formdata?.obj9?.quatity}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj9?.rate}</td>
                                    <td className='text-center py-5 px-3'>$ {props?.formdata?.obj9?.amount}</td>
                                </tr>
                                <tr className='border'>
                                    <td className=' py-3 px-10'></td>
                                    <td className='text-center py-3 px-3'></td>
                                    <td className='text-center py-3 px-3 font-semibold'>Sub Total:</td>
                                    <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount}</td>
                                </tr>
                                <tr className='border'>
                                    <td className=' py-3 px-10'></td>
                                    <td className='text-center py-3 px-3'></td>
                                    <td className='text-center py-3 px-3 font-semibold'>Tax:</td>
                                    <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount * 10 / 100}</td>
                                </tr>
                                <tr className='border'>
                                    <td className=' py-3 px-10'></td>
                                    <td className='text-center py-3 px-3'></td>
                                    <td className='text-center py-3 px-3 font-semibold'>Total:</td>
                                    <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount + (totalAmount * 10 / 100)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="btn-grp flex justify-end">
                            <Button className=" border-2 px-5 py-3 text-white text-lg bg-indigo-600 rounded font-medium mx-5">
                                Send Email
                            </Button>
                            <Button className=" border-2 px-5 py-3 text-white text-lg bg-indigo-600 rounded font-medium">
                                <span className='flex'>
                                    <img src={PrintIcon} alt="" width={22} />
                                    <span className='ml-2'>
                                        Print & Download
                                    </span>
                                </span>
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ScopeOfWork