import Header from "./components/header/Header";
import LandingPage from "./pages/LandingPage";
import Login from "./components/Authentication/Login";
import './App.css'
import Pricing from "./pages/Pricing";
import CorporateUser from "./pages/CorporateUser";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from "./components/Authentication/SignUp";
import Forgot from "./components/Authentication/Forgot";
import DesignAssistant from "./pages/DesignAssistant";
import UploadImage from "./pages/UploadImage";
import CustomerInformation from "./pages/CustomerInformation";
import Appliances from "./pages/Appliances";
import SelectDesign from "./pages/SelectDesign";
import Cabinet from "./pages/Cabinet";
import Cabinet2 from "./pages/Cabinet2";
import HeaderLogin from "./components/header/HeaderLogin";
import LicenceMgt from "./pages/LicenceManagement";
import UpgradePlan from "./pages/UpgradePlan";
import ScopeOfWork from "./pages/ScopeOfWork";
import AdminScopeOfWork from "./pages/AdminScopeOfWork";
import { useState } from "react";
import ManualMeasurement from "./pages/ManualMeasurement";
import SpeechToText from "./pages/SpeechToText";
import Cabinet3 from "./pages/Cabinet3";
import Protected from "./components/Authentication/Protected"


function App() {
  let [formdata,setformdata]= useState()
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<> <Header /> <LandingPage /> </>} />
          <Route path="/pricing" element={<><Header /> <Pricing /> </>} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/designassistant" element={<Protected Component={<><HeaderLogin /> <DesignAssistant /></>} />} />
          <Route path="/customerinformation" element={<Protected Component={<><HeaderLogin /><CustomerInformation /></>} />} />
          <Route path="/uploadimage" element={<Protected Component={<><HeaderLogin /> <UploadImage /></>} />} />
          <Route path="/appliances" element={<Protected Component={<Appliances />} />} />
          <Route path="/corporateuser" element={<Protected Component={<> <Header /> <CorporateUser /> </>} />} />
          <Route path="/selectdesign" element={<Protected Component={<><SelectDesign /> </>} />} />
          <Route path="/cabinet" element={<Protected Component={<><Cabinet /> </>} />} />
          <Route path="/cabinet2" element={<Protected Component={<><Cabinet2 /> </>} />} />
          <Route path="/cabinet3" element={<Protected Component={<><Cabinet3 /> </>} />} />
          <Route path="/licencemanagement" element={<Protected Component={<><HeaderLogin /> <LicenceMgt /></>} />} />
          <Route path="/upgradeplan" element={<Protected Component={<><HeaderLogin /> <UpgradePlan /></>} />} />
          <Route path="/adminscopeofwork" element={<Protected Component={<><AdminScopeOfWork setformdata={setformdata}/> </>} />} />
          <Route path="/scopeofwork" element={<Protected Component={<><ScopeOfWork formdata={formdata} /> </>} />} />
          <Route path="/manualmeasurement" element={<Protected Component={<><HeaderLogin /> <ManualMeasurement /> </>} />} />
          <Route path="/speech" element={<Protected Component={<><HeaderLogin /> <SpeechToText /> </>} />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;


