import React, { useState } from 'react'

import Button from '../components/UI/Button'
import CabinetImg from '../assets/images/kichen-variation/ideare-kitchen-3.png'

import CabinetIcon from '../assets/images/sidebar-icon/cabinet-icon.svg'
import PlumbingIcon from '../assets/images/sidebar-icon/plumbing-icon.svg'
import BacksplashIcon from '../assets/images/sidebar-icon/backsplash-icon.svg'
import PaintIcon from '../assets/images/sidebar-icon/paint-icon.svg'
import FloorIcon from '../assets/images/sidebar-icon/floor-icon.svg'
import CounterIcon from '../assets/images/sidebar-icon/counter-icon.svg'
import DecorIcon from '../assets/images/sidebar-icon/decor-icon.svg'
import HardwareIcon from '../assets/images/sidebar-icon/hardware-icon.svg'
import FauxIcon from '../assets/images/sidebar-icon/faux-icon.svg'
import ExclamIcon from '../assets/images/sidebar-icon/exclamation-icon.svg'
import EllipseIcon from '../assets/images/ellipse-icon.svg'

import ChooseIcon from '../assets/images/choose-app.svg'
import Cabinet1 from '../assets/images/cabinet/v2-cabinet-1.webp'
import Cabinet2 from '../assets/images/cabinet/v2-cabinet-2.png'
import Cabinet3 from '../assets/images/cabinet/v2-cabinet-3.png'
import Cabinet4 from '../assets/images/cabinet/v2-cabinet-4.png'
import Cabinet5 from '../assets/images/cabinet/v2-cabinet-5.png'

import Backsplash1 from '../assets/images/backsplash/v2-backsplash-1.png'
import Backsplash2 from '../assets/images/backsplash/v2-backsplash-2.png'
import Backsplash3 from '../assets/images/backsplash/v2-backsplash-3.png'
import Backsplash4 from '../assets/images/backsplash/v2-backsplash-4.png'
import Backsplash5 from '../assets/images/backsplash/v2-backsplash-5.png'

import EditBacksplash1 from '../assets/images/edit-backsplash/v2-backsplash1.png'
import EditBacksplash2 from '../assets/images/edit-backsplash/v2-backsplash2.png'
import EditBacksplash3 from '../assets/images/edit-backsplash/v2-backsplash3.png'
import EditBacksplash4 from '../assets/images/edit-backsplash/v2-backsplash4.png'
import EditBacksplash5 from '../assets/images/edit-backsplash/v2-backsplash5.png'

import Floor1 from '../assets/images/floor/v2-floor-1.png'
import Floor2 from '../assets/images/floor/v2-floor-2.png'
import Floor3 from '../assets/images/floor/v2-floor-3.png'
import Floor4 from '../assets/images/floor/v2-floor-4.png'
import Floor5 from '../assets/images/floor/v2-floor-5.png'
import Floor6 from '../assets/images/floor/v2-floor-6.png'
import EditFloor1 from '../assets/images/edit-floor/v2-floor1.png'
import EditFloor2 from '../assets/images/edit-floor/v2-floor2.png'
import EditFloor3 from '../assets/images/edit-floor/v2-floor3.png'
import EditFloor4 from '../assets/images/edit-floor/v2-floor4.png'
import EditFloor5 from '../assets/images/edit-floor/v2-floor5.png'
import EditFloor6 from '../assets/images/edit-floor/v2-floor6.png'

import EditCabinet1 from '../assets/images/edit-cabinet/v2-cabinet1.png'
import EditCabinet2 from '../assets/images/edit-cabinet/v2-cabinet2.png'
import EditCabinet3 from '../assets/images/edit-cabinet/v2-cabinet3.png'
import EditCabinet4 from '../assets/images/edit-cabinet/v2-cabinet4.png'
import EditCabinet5 from '../assets/images/edit-cabinet/v2-cabinet5.png'
import EditPaint1 from '../assets/images/edit-paint/v2-paint1.png'
import EditPaint2 from '../assets/images/edit-paint/v2-paint2.png'
import EditPaint3 from '../assets/images/edit-paint/v2-paint3.png'
import EditPaint4 from '../assets/images/edit-paint/v2-paint4.png'
import EditPaint5 from '../assets/images/edit-paint/v2-paint5.png'
import EditPaint6 from '../assets/images/edit-paint/v2-paint6.png'
import EditPaint7 from '../assets/images/edit-paint/v2-paint7.png'
import EditPaint8 from '../assets/images/edit-paint/v2-paint8.png'

import Paint1 from '../assets/images/paint/v2-paint-1.png'
import Paint2 from '../assets/images/paint/v2-paint-2.png'
import Paint3 from '../assets/images/paint/v2-paint-3.png'
import Paint4 from '../assets/images/paint/v2-paint-4.png'
import Paint5 from '../assets/images/paint/v2-paint-5.png'
import Paint6 from '../assets/images/paint/v2-paint-6.png'
import Paint7 from '../assets/images/paint/v2-paint-7.png'
import Paint8 from '../assets/images/paint/v2-paint-8.png'

import EditCountertop1 from '../assets/images/edit-countertop/v2-countertop1.png'
import EditCountertop2 from '../assets/images/edit-countertop/v2-countertop2.png'
import EditCountertop3 from '../assets/images/edit-countertop/v2-countertop3.png'
import EditCountertop4 from '../assets/images/edit-countertop/v2-countertop4.png'
import EditCountertop5 from '../assets/images/edit-countertop/v2-countertop5.png'
import Countertop1 from '../assets/images/countertop/v1-countertop-1.png'
import Countertop2 from '../assets/images/countertop/v1-countertop-3.png'
import Countertop3 from '../assets/images/countertop/v2-countertop-3.png'
import Countertop4 from '../assets/images/countertop/v2-countertop-4.png'
import Countertop5 from '../assets/images/countertop/v2-countertop-5.png'

import EditBaseCabinet1 from '../assets/images/edit-base-cabinet/v2-b-cabinet1.png'
import EditBaseCabinet2 from '../assets/images/edit-base-cabinet/v2-b-cabinet2.png'
import EditBaseCabinet3 from '../assets/images/edit-base-cabinet/v2-b-cabinet3.png'
import EditBaseCabinet4 from '../assets/images/edit-base-cabinet/v2-b-cabinet4.png'
import EditBaseCabinet5 from '../assets/images/edit-base-cabinet/v2-b-cabinet5.png'


import EditIslandCabinet1 from '../assets/images/edit-island-cabinet/v2-i-cabinet1.png'
import EditIslandCabinet2 from '../assets/images/edit-island-cabinet/v2-i-cabinet2.png'
import EditIslandCabinet3 from '../assets/images/edit-island-cabinet/v2-i-cabinet3.png'
import EditIslandCabinet4 from '../assets/images/edit-island-cabinet/v2-i-cabinet4.png'
import EditIslandCabinet5 from '../assets/images/edit-island-cabinet/v2-i-cabinet5.png'

// import VeraChat from './VeraChat'
import LeftSidebar from '../components/Sidebar/LeftSidebar'



function Cabinet() {

    const [toggle, setToggle] = useState(false)
    const [rightData, setRightData] = useState("cabinet")
    // State For Cabinet Image
    const [cabinetType, setCabinetType] = useState(null)
    // State For Cabinet Image
    const [baseCabinetType, setBaseCabinetType] = useState(false)
    // State For Island Cabinet Image
    const [islandCabinetType, setIslandCabinetType] = useState(false)
    // State For Floor Image
    const [floorType, setFloorType] = useState(null)
    // State For Paint Image
    const [paintType, setPaintType] = useState(null)
    // State For Countertop Image
    const [countertopType, setCountertopType] = useState(null)
    // State For Backsplash Image
    const [backsplashType, setBacksplashType] = useState(null)



    function Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const allData = [
        {
            id: 1,
            name: "cabinet",
            img: [Cabinet1, Cabinet2, Cabinet3, Cabinet4, Cabinet5],
            editImg: [EditCabinet1, EditCabinet2, EditCabinet3, EditCabinet4, EditCabinet5]
        },
        {
            id: 2,
            name: "backsplash",
            img: [Backsplash1, Backsplash2, Backsplash3, Backsplash4, Backsplash5],
            editImg: [EditBacksplash1, EditBacksplash2, EditBacksplash3, EditBacksplash4, EditBacksplash5]
        },
        {
            id: 3,
            name: "floor",
            img: [Floor1, Floor2, Floor3, Floor4, Floor5, Floor6],
            editImg: [EditFloor1, EditFloor2, EditFloor3, EditFloor4, EditFloor5, EditFloor6]
        },
        {
            id: 4,
            name: "paint",
            img: [Paint1, Paint2, Paint3, Paint4, Paint5, Paint6, Paint7, Paint8],
            editImg: [EditPaint1, EditPaint2, EditPaint3, EditPaint4, EditPaint5, EditPaint6, EditPaint7, EditPaint8]
        },
        {
            id: 5,
            name: "base-cabinet",
            img: [Cabinet1, Cabinet2, Cabinet3, Cabinet4, Cabinet5],
            editImg: [EditBaseCabinet1, EditBaseCabinet2, EditBaseCabinet3, EditBaseCabinet4, EditBaseCabinet5]
        },
        {
            id: 5,
            name: "island-cabinet",
            img: [Cabinet1, Cabinet2, Cabinet3, Cabinet4, Cabinet5],
            editImg: [EditIslandCabinet1, EditIslandCabinet2, EditIslandCabinet3, EditIslandCabinet4, EditIslandCabinet5]
        },
        {
            id: 6,
            name: "red-paint",
            img: [Paint2, Paint3],
            editImg: [EditPaint2, EditPaint3]
        },
        {
            id: 7,
            name: "yellow-paint",
            img: [Paint5],
            editImg: [EditPaint5]
        },
        {
            id: 8,
            name: "gray-paint",
            img: [Paint1, Paint4],
            editImg: [EditPaint1, EditPaint4]
        },
        {
            id: 9,
            name: "blue-paint",
            img: [Paint6],
            editImg: [EditPaint6]
        },
        {
            id: 10,
            name: "pink-paint",
            img: [Paint8],
            editImg: [EditPaint8]
        },
        {
            id: 11,
            name: "green-paint",
            img: [Paint7],
            editImg: [EditPaint7]
        },
        {
            id: 12,
            name: "countertop",
            img: [Countertop1, Countertop2, Countertop3, Countertop4, Countertop5],
            editImg: [EditCountertop1, EditCountertop2, EditCountertop3, EditCountertop4, EditCountertop5]
        }
    ]
    // Function to handle img click
    const handleDesignClick = (index, type, func) => {
        // Assuming there's only one item in allData
        const cabinetItem = allData.find(item => item.name === type);
        if (cabinetItem && cabinetItem.editImg[index]) {
            func(cabinetItem.editImg[index]);
        }
    };

    return (
        <>
            <div className="flex justify-between overflow-x-hidden">

                {/* **********************LEFT SIDEBAR**************** */}
                <LeftSidebar kitchenImg={CabinetImg} toggle={toggle} setToggle={setToggle} modalNavigation={"/cabinet3"}/>

                {/* *******************EDIT IMAGE MID SECTION*************** */}
                <div className={`mid-wrapper new--bg h-screen w-full mx-4 overflow-y-hidden ${toggle ? "mid-container-pad" : ""}`}>
                    <div className=" relative">
                        <img src={CabinetImg} alt="" />
                        <div className="edit-floor absolute top-0">
                            <img src={floorType} alt="" />
                        </div>
                        <div className="edit-cabinet absolute top-0">
                            <img src={cabinetType} alt="" />
                        </div>
                        <div className="edit-wall absolute top-0">
                            <img src={paintType} alt="" />
                        </div>
                        <div className="edit-wall absolute top-0">
                            <img src={baseCabinetType} alt="" />
                        </div>
                        <div className="edit-wall absolute top-0">
                            <img src={islandCabinetType} alt="" />
                        </div>
                        <div className="edit-wall absolute top-0">
                            <img src={countertopType} alt="" />
                        </div>
                        <div className="edit-wall absolute top-0">
                            <img src={backsplashType} alt="" />
                        </div>

                        <div className={`absolute right-0 z-10 ${toggle?"bottom-0":"bottom-6"}`}>
                            {/* <VeraChat /> */}
                        </div>
                        {/* 2D Blueprint Screen  */}
                        {/* <div className="absolute left-5 bottom-5 py-3 px-6 bg-white rounded-xl">
                            <img src={BluePrint} alt="" />
                        </div> */}
                        {toggle ? (
                            <>
                                <span className="design-selector v2-wcabinet-changer selector-bg" onClick={() => setRightData("wall-cabinet")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-bcabinet-changer selector-bg" onClick={() => setRightData("base-cabinet")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-icabinet-changer selector-bg" onClick={() => setRightData("island-cabinet")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-floor-changer selector-bg" onClick={() => setRightData("floor")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-countertop-changer selector-bg" onClick={() => setRightData("countertop")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-paint-changer selector-bg" onClick={() => setRightData("paint")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                                <span className="design-selector v2-backsplash-changer selector-bg" onClick={() => setRightData("backsplash")}>
                                    <img src={EllipseIcon} alt="" />
                                </span>
                            </>
                        ) : ""}
                    </div>
                </div>


                {/* *************RIGHT SIDEBAR********************** */}
                <div className='right-wrapper flex items-center'>
                    <div className="right-toggle" onClick={() => setToggle(!toggle)}>
                        <div className='right-icon grad-color py-7 px-4 rounded-l-lg cursor-pointer'>
                            <img src={ChooseIcon} alt="" />
                        </div>
                    </div>
                    <div className={`right-sidebar flex ${toggle ? "right-active" : "right-inactive"}`}>
                        <div className="grad-color px-4 py-6 h-screen w-[125px]">
                            <div className={`${rightData === "cabinet" || rightData === "base-cabinet" || rightData === "wall-cabinet" || rightData === "island-cabinet" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("cabinet")}>
                                <img className='mb-3' src={CabinetIcon} alt="" width={22} />
                                <h5 className='text-sm'>Cabinets</h5>
                            </div>
                            <div className={`${rightData === "backsplash" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("backsplash")}>
                                <img className='mb-3' src={BacksplashIcon} alt="" width={32} />
                                <h5 className='text-sm'>Backsplash</h5>
                            </div>
                            <div className={`${rightData === "paint" || rightData === "red-paint" || rightData === "yellow-paint" || rightData === "gray-paint" || rightData === "blue-paint" || rightData === "pink-paint" || rightData === "green-paint" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("paint")}>
                                <img className='mb-3' src={PaintIcon} alt="" width={32} />
                                <h5 className='text-sm'>Paint</h5>
                            </div>
                            <div className={`${rightData === "floor" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("floor")}>
                                <img className='mb-3' src={FloorIcon} alt="" width={32} />
                                <h5 className='text-sm'>Floor</h5>
                            </div>
                            <div className={`${rightData === "countertop" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("countertop")}>
                                <img className='mb-3' src={CounterIcon} alt="" width={32} />
                                <h5 className='text-sm'>Countertop</h5>
                            </div>
                            <div className={`${rightData === "plumbing" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("plumbing")}>
                                <img className='mb-3' src={PlumbingIcon} alt="" width={32} />
                                <h5 className='text-sm'>Plumbing</h5>
                            </div>
                            <div className={`${rightData === "decor" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("decor")}>
                                <img className='mb-3' src={DecorIcon} alt="" width={32} />
                                <h5 className='text-sm'>Decor</h5>
                            </div>
                            <div className={`${rightData === "hardware" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("hardware")}>
                                <img className='mb-3' src={HardwareIcon} alt="" width={32} />
                                <h5 className='text-sm'>Hardware</h5>
                            </div>
                            <div className={`${rightData === "faux" ? "border" : ""} flex flex-col text-white justify-center items-center text-center px-2 py-3 mb-2 cursor-pointer`} onClick={() => setRightData("faux")}>
                                <img className='mb-3' src={FauxIcon} alt="" width={32} />
                                <h5 className='text-sm'>Faux Beams</h5>
                            </div>
                        </div>
                        <div className="grad-color right-sidebar1 py-3 px-3">
                            {rightData === "cabinet" || rightData === "base-cabinet" || rightData === "wall-cabinet" || rightData === "island-cabinet" ? (
                                <div>
                                    <div className="flex text-white button-grp">
                                        <div>
                                            <Button className={`text-sm border py-3 px-4 mx-2 rounded-md ${rightData === "base-cabinet" ? "active-filter" : ""}`} onClick={() => setRightData("base-cabinet")}>BASE CABINET</Button>
                                        </div>
                                        <div>
                                            <Button className={`text-sm border py-3 px-5 mx-2 rounded-md ${rightData === "wall-cabinet" ? "active-filter" : ""}`} onClick={() => setRightData("wall-cabinet")}>WALL CABINET</Button>
                                        </div>
                                    </div>
                                    <hr className='my-3' />
                                </div>
                            ) : ""}
                            {rightData === "paint" || rightData === "red-paint" || rightData === "yellow-paint" || rightData === "gray-paint" || rightData === "blue-paint" || rightData === "pink-paint" || rightData === "green-paint" ? (
                                <div>
                                    <div className="flex text-white button-grp">
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-red-500 w-[50px] h-[40px] cursor-pointer`} onClick={() => setRightData("red-paint")}></div>
                                        </div>
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-yellow-300 w-[50px] h-[40px] cursor-pointer`} onClick={() => setRightData("yellow-paint")}></div>
                                        </div>
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-gray-500 w-[50px] h-[40px] cursor-pointer`} onClick={() => setRightData("gray-paint")}></div>
                                        </div>
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-blue-500 w-[50px] h-[40px] cursor-pointer `} onClick={() => setRightData("blue-paint")}></div>
                                        </div>
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-pink-500 w-[50px] h-[40px] cursor-pointer `} onClick={() => setRightData("pink-paint")}></div>
                                        </div>
                                        <div>
                                            <div className={`text-sm border py-3 px-4 mx-2 rounded-md bg-green-500 w-[50px] h-[40px] cursor-pointer`} onClick={() => setRightData("green-paint")}></div>
                                        </div>

                                    </div>
                                    <hr className='my-3' />
                                </div>
                            ) : ""}
                            {/* <div>
                                    <Button className="border py-3 px-7 mx-2 w-[200px] rounded-md">ISLAND CABINET</Button>
                                </div>
                                <div>
                                    <Button className="border py-3 px-7 mx-2 w-[190px] rounded-md">BASE MOLDING</Button>
                                </div> */}
                            <div className="r-data  my-10">
                                {rightData === "cabinet" ? (<div className="r-cabinet flex flex-wrap ">
                                    {allData.filter(item => item.name === 'cabinet')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border cabinet-box rounded-md px-7 py-3 mx-2 my-2 cursor-pointer" onClick={() => handleDesignClick(index, "cabinet", setCabinetType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))}

                                </div>) : ""}
                                {rightData === "base-cabinet" ? (<div className="r-cabinet flex flex-wrap ">
                                    {allData.filter(item => item.name === 'base-cabinet')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border cabinet-box rounded-md px-7 py-3 mx-2 my-2 cursor-pointer" onClick={() => handleDesignClick(index, "base-cabinet", setBaseCabinetType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))}

                                </div>) : ""}
                                {rightData === "wall-cabinet" ? (<div className="r-cabinet flex flex-wrap ">
                                    {allData.filter(item => item.name === 'cabinet')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border cabinet-box rounded-md px-7 py-3 mx-2 my-2 cursor-pointer" onClick={() => handleDesignClick(index, "cabinet", setCabinetType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))}

                                </div>) : ""}
                                {rightData === "island-cabinet" ? (<div className="r-cabinet flex flex-wrap ">
                                    {allData.filter(item => item.name === 'island-cabinet')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border cabinet-box rounded-md px-7 py-3 mx-2 my-2 cursor-pointer" onClick={() => handleDesignClick(index, "island-cabinet", setIslandCabinetType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))}

                                </div>) : ""}
                                {rightData === "backsplash" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'backsplash')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer" onClick={() => handleDesignClick(index, "backsplash", setBacksplashType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            ))
                                        )
                                    }
                                </div>) : ""}
                                {rightData === "paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "red-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'red-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "red-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "yellow-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'yellow-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "yellow-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "gray-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'gray-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "gray-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "blue-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'blue-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "blue-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "pink-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'pink-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "pink-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "green-paint" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'green-paint')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "green-paint", setPaintType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "floor" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'floor')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "floor", setFloorType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "countertop" ? (<div className="r-paint flex flex-wrap ">
                                    {allData.filter(item => item.name === 'countertop')
                                        .map((item) =>
                                            item.img.map((image, index) => (
                                                <div key={index} className="border rounded-md paint-box mx-2 my-2 cursor-pointer cursor-pointer" onClick={() => handleDesignClick(index, "countertop", setCountertopType)}>
                                                    <img src={image} alt="" />
                                                </div>
                                            )))
                                    }
                                </div>) : ""}
                                {rightData === "plumbing" || rightData === "decor" || rightData === "hardware" || rightData === "faux" ? (<div className="flex flex-col items-center">
                                    <img src={ExclamIcon} alt="" width={50} />
                                    <p className='text-white font-medium text-lg text-center my-4'>{Capitalize(rightData)} is not applicable for this particular kitchen.</p>
                                    <p className='text-white font-medium text-lg text-center'>Please explore other options.</p>
                                </div>) : ""}
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            
        </>
    )
}

export default Cabinet
