import React, { useState } from 'react'
import Card from '../components/UI/Card'
import Input from '../components/UI/Input'
import { Link } from 'react-router-dom'
import LeftIcon from '../assets/images/left.png'
import RightIcon from '../assets/images/right.png'


function CustomerInformation() {
    const [showType, setShowType] = useState(false)
    const [projectType, setProjectType] = useState("PROJECT TYPE")
    const toggleProjectType=()=>{
        setShowType(!showType)
    }
    const changeProjectType=(name)=>{
        setProjectType(name)
        setShowType(false)
    }
    const name = sessionStorage.getItem('user_name');
    return (
        <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex justify-center items-center">
            <Card className="box-1">
                <p className='box-p info'>CUSTOMER INFORMATION</p>
                <div className='flex'>
                    <Input
                        type="text"
                        name="username"
                        className="user1 mr-2 transprent placeholder-white focus:outline-transparent"
                        value={name}
                    />
                    <div className="role w-11/12 ">
                        <ul>
                            <li className='text-white project-type relative' onClick={toggleProjectType}>
                            {projectType}
                            <span className='project-drop'>
                                <i className={`p-arrow ml-5 text-white ${showType?"up":"down"}`}></i>
                            </span>
                            </li>
                        </ul>
                        <ul className={` cursor-pointer rounded project-options absolute font-medium ${showType?"block":"hidden"}`}>
                            <li className='text-left' onClick={()=>changeProjectType("KITCHEN")}><span className=' mx-4'>KITCHEN</span></li>
                            <li className='text-left' onClick={()=>changeProjectType("BATHROOM")}><span className="mx-4">BATHROOM</span></li>
                            <li className='text-left' onClick={()=>changeProjectType("PRIMARY BATHROOM")}><span className="mx-4">PRIMARY BATHROOM</span></li>
                            <li className='text-left' onClick={()=>changeProjectType("GUEST BATHROOM")}><span className="mx-4">GUEST BATHROOM</span></li>
                            <li className='text-left' onClick={()=>changeProjectType("HALL BATHROOM")}><span className="mx-4">HALL BATHROOM</span></li>
                            <li className='text-left' onClick={()=>changeProjectType("FIREPLACE")}><span className="mx-4">FIREPLACE</span></li>
                        </ul>
                    </div>
                </div>
                <Input
                    type="text"
                    name="username"
                    className="user2 transprent placeholder-white focus:outline-transparent"
                    placeholder="Address"
                />
                <div className='iconsvg pt-3'>
                    <Link to="/designassistant"><img src={LeftIcon} alt='img' className='arrow-img' /></Link>
                    <Link to="/uploadimage"><img src={RightIcon} alt='img' className='arrow-img' /></Link>
                </div>
            </Card>
        </div>
    )
}

export default CustomerInformation