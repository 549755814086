import React, { useEffect, useState } from 'react'
import Card from '../components/UI/Card'
import { Link } from 'react-router-dom'
import LeftIcon from '../assets/images/left.png'
import RightIcon from '../assets/images/right.png'
import Ref1 from '../assets/images/products/ref1.png'
import Ref2 from '../assets/images/products/ref2.png'
import Ref3 from '../assets/images/products/ref3.png'
import Ref4 from '../assets/images/products/ref4.png'
import Ref6 from '../assets/images/products/ref6.png'
import Oven1 from '../assets/images/products/oven1.png'
import Oven2 from '../assets/images/products/oven2.png'
import Oven3 from '../assets/images/products/oven3.png'
import Oven4 from '../assets/images/products/oven4.png'
import Ctop1 from '../assets/images/products/ctop1.png'
import Ctop2 from '../assets/images/products/ctop2.png'
import Ctop3 from '../assets/images/products/ctop3.png'
import Ctop4 from '../assets/images/products/ctop4.png'
import Ctop5 from '../assets/images/products/ctop5.png'
import Micro1 from '../assets/images/products/micro1.png'
import Micro2 from '../assets/images/products/micro2.png'
import Micro3 from '../assets/images/products/micro3.png'
import Micro4 from '../assets/images/products/micro4.png'
import Micro5 from '../assets/images/products/micro5.png'
import Micro6 from '../assets/images/products/micro6.png'
import Micro7 from '../assets/images/products/micro7.png'
import Dwash1 from '../assets/images/products/dwash1.png'
import Dwash2 from '../assets/images/products/dwash2.png'
import Dwash3 from '../assets/images/products/dwash3.png'
import Dwash4 from '../assets/images/products/dwash4.png'
import Dwash5 from '../assets/images/products/dwash5.png'
import Dwash6 from '../assets/images/products/dwash6.png'
import Laundry1 from '../assets/images/products/laundry1.png'
import Laundry2 from '../assets/images/products/laundry2.png'
import Laundry3 from '../assets/images/products/laundry3.png'
import Laundry4 from '../assets/images/products/laundry4.png'
import Laundry5 from '../assets/images/products/laundry5.png'
import Coffee1 from '../assets/images/products/coffee1.png'
import Coffee2 from '../assets/images/products/coffee2.png'
import Coffee3 from '../assets/images/products/coffee3.png'
import Coffee4 from '../assets/images/products/coffee4.png'
import Coffee5 from '../assets/images/products/coffee5.png'
import Coffee6 from '../assets/images/products/coffee6.png'
import Coffee7 from '../assets/images/products/coffee7.png'
import Freezer1 from '../assets/images/products/freezer1.png'
import Freezer2 from '../assets/images/products/freezer2.png'
import Freezer3 from '../assets/images/products/freezer3.png'
import Freezer4 from '../assets/images/products/freezer4.png'
import Comp1 from '../assets/images/products/comp1.png'
import Comp2 from '../assets/images/products/comp2.png'
import Comp3 from '../assets/images/products/comp3.png'
import Ice1 from '../assets/images/products/ice1.png'
import Ice2 from '../assets/images/products/ice2.png'
import Ice3 from '../assets/images/products/ice3.png'
import Ice4 from '../assets/images/products/ice4.png'
import Hood1 from '../assets/images/products/hood1.png'
import Hood2 from '../assets/images/products/hood2.png'
import Hood3 from '../assets/images/products/hood3.png'
import Hood4 from '../assets/images/products/hood4.png'




import AppliancesCard from './AppliancesCard'



function Appliances() {
    const appliances = [
        {
            id: 1,
            name: "Refrigerator",
            category: "refrigerator",
            img: [{ img: Ref6, size: "36 inch", type: "Single Door" }, { img: Ref2, size: "48 inch", type: "Double Door" }, { img: Ref3, size: "64 inch", type: "Side-By-Side" }, { img: Ref4, size: "Under Counter", type: "French Door" }, { img: [ Ref1], size: "Built-in", type: "Multi-Door/Smart" },],
            size:true,
            type:true
        },
        {
            id: 2,
            name: "Coffee Maker",
            category: "coffee-maker",
            img: [{ img: Coffee1 }, { img: Coffee2 }, { img: Coffee3 }, { img: Coffee4 }, { img: Coffee5 }, { img: Coffee6 }, { img: Coffee7 }],
            size:false,
            type:false

        },
        {
            id: 3,
            name: "Freezer",
            category: "freezer",
            img: [{ img: Freezer1 }, { img: Freezer2 }, { img: Freezer3 }, { img: Freezer4 }],
            size:false,
            type:false
        },
        {
            id: 4,
            name: "Oven",
            category: "oven",
            img: [{ img: Oven1 }, { img: Oven2 }, { img: Oven3 }, { img: Oven4 }],
            size:false,
            type:false
        },
        {
            id: 5,
            name: "Cooktop",
            category: "cooktop",
            img: [{ img: Ctop1 }, { img: Ctop2 }, { img: Ctop3 }, { img: Ctop4 }, { img: Ctop5 }],
            size:false,
            type:false
        },
        {
            id: 6,
            name: "Microwave",
            category: "microwave",
            img: [{ img: Micro1 }, { img: Micro2 }, { img: Micro3 }, { img: Micro4 }, { img: Micro5 }, { img: Micro6 }, { img: Micro7 }],
            size:false,
            type:false
        },
        {
            id: 7,
            name: "Dishwasher",
            category: "dishwasher",
            img: [{ img: Dwash1 }, { img: Dwash2 }, { img: Dwash3 }, { img: Dwash4 }, { img: Dwash5 }, { img: Dwash6 }],
            size:false,
            type:false
        },
        {
            id: 8,
            name: "Compactor",
            category: "compactor",
            img: [{ img: Comp1 }, { img: Comp2 }, { img: Comp3 }],
            size:false,
            type:false
        },
        {
            id: 9,
            name: "Laundry",
            category: "laundry",
            img: [{ img: Laundry1 }, { img: Laundry2 }, { img: Laundry3 }, { img: Laundry4 }, { img: Laundry5 }],
            size:false,
            type:false
        },
        {
            id: 10,
            name: "Ice Maker",
            category: "ice-maker",
            img: [{ img: Ice1 }, { img: Ice2 }, { img: Ice3 }, { img: Ice4 }],
            size:false,
            type:false
        },
        {
            id: 11,
            name: "Range Hood",
            category: "range-hood",
            img: [{ img: Hood1 }, { img: Hood2 }, { img: Hood3 }, { img: Hood4 }],
            size:false,
            type:false
        }
    ]
    const defaultAppliances = [
        {
            id: 1,
            name: "Refrigerator",
            category: "refrigerator",
            img: [
                { img: Ref6, size: "36 inch", type: "Single Door" }, 
                { img: Ref2, size: "48 inch", type: "Double Door" }, 
                { img: Ref3, size: "64 inch", type: "Side-By-Side" }, 
                { img: Ref4, size: "Under Counter", type: "French Door" }, 
                { img: Ref4, size: "Built-in", type: "Multi-Door/Smart" }, 
                { img: Ref1, size: "Built-in", type: "Industrial" },
            ],
            size:true,
            type:true
        }
    ]
    const [selectedCategory, setSelectedCategory] = useState("refrigerator")
    const [appArr, setAppArr] = useState(appliances)
    const [size, SetSize] = useState('');
    const [category, SetCategory] = useState('');
    const [dropSize, SetDropSize] = useState(true)
    const [dropType, SetDropType] = useState(true)


    function handleAppliance(data) {
        setSelectedCategory(data.category);
        data.size? SetDropSize(true) : SetDropSize(false)
        data.type ? SetDropType(true) : SetDropType(false)

    }

    useEffect(() => {
        setAppArr(appliances?.filter((item) => item?.category === selectedCategory));
    }, [selectedCategory])

    useEffect((e) => {
        const filterImage = defaultAppliances[0]?.img?.filter((item) => {
            return item.size.includes(size) && item.type.includes(category)
        })

        if (filterImage.length > 0) {
            setAppArr([{ ...appArr, img: filterImage }]);
        }
        else {
            setAppArr([])
        }
    }, [size, category])
    // const aa=appliances?.map((elem) => elem?.img[0])
    return (
        <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex justify-start items-center">
            <Card className="card z-10 LoginModal flex p-0 h-full xl:w-6/12">
                <div className='container box-1 lg:min-w-[300px] md:min-w-[250px] sm:min-w-[200px]'>
                    <h3 className='image-p font-sans mb-2 text-lg pt-0'>CHOOSE APPLIANCES</h3>
                    <div className='side-bar'>
                        {appliances?.map((data) => {
                            return (
                                <button className={`bg-white p-3 rounded text-gray-600 block m-2 w-full ${selectedCategory===data.category ? "active-btn":""}`} onClick={() => handleAppliance(data)}>{data.name}</button>
                            )
                        }
                        )}
                    </div>
                    <div className='iconsvg pt-3'>
                        <Link to="/uploadimage"><img src={LeftIcon} alt='img' className='arrow-img' /></Link>
                        <Link to="/selectdesign"><img src={RightIcon} alt='img' className='arrow-img' /></Link>
                    </div>
                </div>
                <div className='container p-3'>
                    <h2 className='font-bold text-xl pt-2 pb-4' style={{ textTransform: "uppercase" }}>{selectedCategory} TYPES</h2>
                    <div className="role">
                        {dropSize?
                        appliances?.map((elem) => (
                            elem?.img[0].size ? (<div className='flex mobile-flex-wrap'>
                                <label htmlFor="username" className="font-bold w-4/12 mobile-full">
                                    CHOOSE SIZE
                                </label>
                                <select name="" id="role" className='w-8/12 mobile-full' onChange={(e) => SetSize(e.target.value)} >
                                    <option value="">NONE</option>
                                    {elem.img?.map((i) => <option value={i.size}>{i.size}</option>)}
                                </select>
                            </div>) : ""
                        ))
                        :""
                    }
                        {dropType ? appliances?.map((el) => (
                            el?.img[0].type ? (<div className='flex mobile-flex-wrap'>
                                <label htmlFor="username" className="font-bold w-4/12 mobile-full">
                                    CHOOSE TYPE
                                </label>
                                <select name="" id="role" className='w-8/12 mobile-full' onChange={(e) => SetCategory(e.target.value)}>
                                    <option value="">NONE</option>
                                    {el?.img?.map((i) => <option value={i.type}>{i.type}</option>)}
                                </select>
                            </div>) : ""
                        )):""}

                        <hr className="font-bold" />
                    </div>
                    
                    <div className='overflow-y-auto max-h-[80%]'>
                        <div className='flex flex-wrap appliances-container'>
                            {appArr?.map((val) => (
                                <span key={val.id} className='flex flex-wrap'>
                                    {val?.img?.map((image, index) => (
                                        <AppliancesCard index={index} image={image.img} key={index} />
                                    ))}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default Appliances
