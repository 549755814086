import * as Yup from "yup";

export const signupVal = Yup.object({
    username :Yup.string().required("Please enter the username"),
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
        /^(?=.*[a-z])/,
        "At least one lowercase character"
    )
    .matches(
        /^(?=.*[A-Z])/,
        " At least one uppercase character"
    )
    .matches(
        /^(?=.*[0-9])/,
        "At least one number character"
    )
    .matches(
        /^(?=.*[!@#%^&$*])/,
        "At least one special case character"
    )
    .required('Please enter password'),

conPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm Password must be match').required('Please Enter Confirm Password'),
})

export const loginVal = Yup.object({
    username :Yup.string().required("Please enter the username"),
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
        /^(?=.*[a-z])/,
        "At least one lowercase character"
    )
    .matches(
        /^(?=.*[A-Z])/,
        " At least one uppercase character"
    )
    .matches(
        /^(?=.*[0-9])/,
        "At least one number character"
    )
    .matches(
        /^(?=.*[!@#%^&$*])/,
        "At least one special case character"
    )
    .required('Please enter password'),
})

export const forgotVal = Yup.object({
    username :Yup.string().required("Please enter the username"),
})