import React, { useContext, useState } from 'react'
import Logo from '../../assets/images/IDEARE final.png'
import { Link } from 'react-router-dom'
import {  signOut } from "firebase/auth";
import {auth} from '../../utils/firebase.utils';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Authentication/AuthContext';

const HeaderLogin = () => {
    const navigate = useNavigate();
    const [logShow, setLogShow] = useState(false)

    const toggleLogShow = () => {
        setLogShow(!logShow)
    }
    const userName = sessionStorage.getItem("user");
    const userAvatar = sessionStorage.getItem("avatar")
    const handleLogout = () => {              
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/login");
            sessionStorage.removeItem("isLogin")
            console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }

    return (
        <>
            <header className="header flex justify-between items-center py-3 sticky">
                <div className="logo mx-3">
                    <Link to="/"><img src={Logo} alt="" className='w-28' /></Link>
                </div>
                <div className="login-menu flex items-center">
                    <img src={userAvatar} className="w-12 mx-2 rounded-full" alt="Avatar" />
                    <ul className='flex font-medium mr-3'>
                        <li className='px-2 cursor-pointer' onClick={toggleLogShow}>
                            {userName} 
                            <i className={`arrow ml-5 ${logShow?"up":"down"}`}></i>
                        </li>
                    </ul>
                    <ul className={`cursor-pointer rounded log-options absolute font-medium ${logShow?"block":"hidden"}`}>
                            <li>Edit profile</li>
                            <li><Link to={"/licencemanagement"}>Settings</Link></li>
                            <li onClick={handleLogout}>Log out</li>
                        </ul>
                </div>
            </header>
        </>
    )
}

export default HeaderLogin