import { Switch } from '@mui/material';
import React from 'react';
import DelIcon from '../assets/images/del-icon.svg'

const DataTable = ({ data, handleDelete }) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    function handleDel(index){
        handleDelete(index)
    }

    return (
        <>
            <table className='lic-table w-full'>
                <thead>
                    <tr className=' border-2 py-2 font-semibold'>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Designation</td>
                        <td>Duration</td>
                        <td>Active/Inactive</td>
                        <td >Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        return (
                            <tr key={index} className=' border-2 py-2 font-semibold text-gray-500'>
                                <td>{index + 1}</td>
                                <td>{row.name ? row.name : "User"}</td>
                                <td>Manager</td>
                                <td>5 Days</td>
                                <td className='flex justify-center items-center'><Switch {...label} defaultChecked /></td>
                                <td><span className='flex justify-center items-center cursor-pointer' onClick={()=>handleDel(index)}><img src={DelIcon} width={25} alt="" /></span></td>
                            </tr>
                        )
                    })}

                </tbody>

            </table>
        </>
    );
};

export default DataTable;
