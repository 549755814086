import React, { useEffect, useRef, useState } from 'react'
import Card from '../components/UI/Card'
import { useNavigate } from 'react-router-dom'
import PredictPopUp from './PredictPopUp'
import axios from 'axios'
import {predictUrl, uploadUrl} from '../configuration/config'
import UploadIcon from '../assets/images/upload.png'

function UploadImage() {
  const navigate = useNavigate()
  const inputFileRef = useRef(null); // Create a ref for the file input
  const [predictModal,setPredictModal]=useState(false)
  const [uploadImageUrl, setUploadImageUrl] = useState('');
  const [getImageUrls, setGetImageUrl] = useState({});
  const [imageId, setImageId] = useState(-1);

  // UPLOADING API **********
  const uploadImage = async (value) => {
    try {
        const formdata = new FormData();
        formdata.append("image",value);
        formdata.append('user_id', sessionStorage.getItem('user_id') || 'default');
        const response = await axios.post(uploadUrl,formdata,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
       setUploadImageUrl(response.data.image_url);
       setImageId(response.data.image_id);
       setPredictModal(true)
    } catch (error) {
        console.error('Error fetching image:', error);
        // setUploadImageUrl({cors:true})
    }
};

// FETCH API ***********
const fetchImage = async () => {
    try {
      const params = new FormData();
      params.append('user_id', sessionStorage.getItem('user_id') || 'default')
      params.append('image_id', imageId );  
      const response = await axios.post(predictUrl, params);
      setGetImageUrl({original:response.data.original_image_url,predicted:response.data.predicted_image_url});
    } catch (error) {
        console.error('Error fetching image:', error);
    }
};
useEffect(() => {
    if(uploadImageUrl!==''){
     fetchImage();
    }
 }, [uploadImageUrl]);

// For File Upload Functionality 
const handleUploadButtonClick = () => {
    // Simulate a click on the file input
    inputFileRef.current.click();
};


const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    uploadImage(selectedFile);
};

  const handleMeasurementPage = () => {
    navigate('/manualmeasurement');
  }

  return (
    <>
    <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex justify-center items-center">
      <Card className="box-1">
        <img src={UploadIcon} alt='img' className='mx-auto block mb-3 custom-file-upload cursor-pointer' onClick={handleUploadButtonClick} />
        {/* Invisible file input */}
        <input
          type="file"
          id="file"
          ref={inputFileRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <h3 className='image-p font-sans mb-2'>Upload images</h3>
        <h6 className='box-h6'>upload your actual furnished or empty space, sketch, 3D model image.</h6>
        <h6 className='box-image'>supported JPG, PNG formates.</h6>
        <p className='box-span'>OR</p>

        <button className='box-btn-img mx-auto block' onClick={handleMeasurementPage}>MANUAL MEASUREMENTS</button>
      </Card>
    </div>
    {
                predictModal ?
                <PredictPopUp originalImg={`${getImageUrls?.original}`} predictedImg={`${getImageUrls?.predicted}`} setPredictModal={setPredictModal} navigation={"/appliances"}/> : ""
            }
    </>
  )
}

export default UploadImage