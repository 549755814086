import React from 'react'

const Modal = (props) => {
    return (
        <div className="main-overlay backdrop-filter backdrop-blur-sm" >

            <div className={props.className}>
                {
                    props.children
                }
            </div>
        </div>
    )
}

export default Modal