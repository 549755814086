import React from 'react'
import Card from "../components/UI/Card";
import Input from '../components/UI/Input';
import Button from "../components/UI/Button"
import { Link } from "react-router-dom";
const Pricing = () => {
    return (
        <>
            <section className="main-wrapper h-screen bg-no-repeat bg-cover bg-center banner-grad relative">
                <div className="plan text-center translate-x-0 z-10 pt-24 ">
                    <h1 className="text-gray-50 text-5xl mb-24">
                        Plan & Pricing
                    </h1>
                    <div className="xl:container mx-auto my-10">
                        <div className="md:flex px-3 md:px-0">
                            <Card className='p-card  rounded-l-md'>
                                <h1>INDIVIDUAL</h1>
                                <p>Lorem cb dchipsum dolor sit amet consectetur adipisicing elit. Autem minima quo commodi, repellendus soluta alias. </p>
                                <p className='text-start font-semibold mb-9'>User Licence Access: 1</p>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>With headset</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$2195.00/year</p>
                                    </div>
                                </div>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>Without headset</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$1895.00/year</p>
                                    </div>
                                </div>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>Room Recoginition</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$500.00/year</p>
                                    </div>
                                </div>
                                <Button className='primary-btn text-lg font-medium'>SUBSCRIBE</Button>



                            </Card>
                            <Card className='p-card rounded-md md:-my-11 bg-indigo-50'>
                                <h1>CORPORATE</h1>
                                <p>Lorem cb dchipsum dolor sit amet consectetur adipisicing elit. Autem minima quo commodi, repellendus soluta alias. </p>
                                <div className="flex items-center my-5">
                                    <p className='text-start font-semibold pr-2 '>User Licence Access:</p>
                                    <select name="" id="" className='mb-0 w-24'>
                                        <option value="">1-4</option>
                                        <option value="">5-9</option>
                                    </select>
                                </div>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>With headset</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$2195.00/year</p>
                                    </div>
                                </div>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>Without headset</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$1895.00/year</p>
                                    </div>
                                </div>
                                <div className="flex justify-between border border-gray-400 py-3 px-3 rounded-md items-center mb-7">
                                    <div className="headset font-medium">
                                        <Input type='radio' className='mr-2' />
                                        <label>Room Recoginition</label>
                                    </div>
                                    <div className="price font-medium">
                                        <p>$500.00/year</p>
                                    </div>
                                </div>
                                <Link to="/corporateuser"><Button className='primary-btn text-lg font-medium'>SUBSCRIBE</Button>
                                </Link>
                            </Card>
                            <Card className='p-card flex rounded-r-md'>
                                <div className="flex flex-col justify-between">
                                    <div className="enterprise-content">
                                        <h1>ENTERPRISE</h1>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem minima quo commodi, repellendus soluta alias.
                                        </p>
                                    </div>
                                    <p className='font-semibold text-2xl text-gray-500'>If more than 10 user licence access needed. </p>
                                    <Button className='primary-btn text-lg font-medium'>SUBSCRIBE</Button>
                                </div>

                            </Card>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}



export default Pricing