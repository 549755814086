import { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [userAvatars, setUserAvatar] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loginContext = ( username,avatar) => {
    setUsername(username);
    setUserAvatar(avatar);
    setIsLoggedIn(true);
  };

  const logoutContext = () => {
    setUserAvatar(null);
    setUsername(null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ username, isLoggedIn,userAvatars, loginContext, logoutContext }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };