import React from 'react'

const AppliancesCard = ({index,image}) => {
    return (
        <>
            <div key={index} className='border mx-2 my-2 p-2 max-w-[185px] flex items-center'>
                <img src={image} alt='img' className=' custom-padding text-gray-600 ' />
            </div>
        </>
    )
}

export default AppliancesCard