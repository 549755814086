
import React, { useState } from 'react'
import Card from '../components/UI/Card'
import Modal from '../components/UI/Modal'
import Input from '../components/UI/Input'
import { Grid } from '@mui/material'


const PopupForm = ({ onSubmit, closeForm }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [showMulti, setShowMulti] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, email });
        setName('');
        setEmail('');
    };

    const handleToggle = () => {
        closeForm()
    }

    return (

        <div>
            <div className={`${showMulti ? "hidden" : "false"}`}>
                <Modal >
                    <form onSubmit={handleSubmit}>
                        <Card className="bg-white lg:min-w-[850px] lg:min-h-[360px] rounded p-10">
                            <div className='flex justify-between'>
                                <h3 className='text-2xl font-semibold'>Invite Members</h3>
                                <span className='text-xl cursor-pointer' onClick={handleToggle}>&#x2716;</span>
                            </div>
                            <div className="mt-7">
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='m-7'>
                                    <Grid item xs={6} className=''>
                                        <h4 className='text-xl font-semibold text-gray-500'>Email Address</h4>
                                    </Grid>
                                    <Grid item xs={6} className=''>
                                        <h4 className='text-xl font-semibold text-gray-500'>Name (Optional)</h4>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="flex">
                                <Input className=" mr-5" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <Input className="mr-5 ml-8" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                <span className='text-xl text-gray-500 mt-2 cursor-pointer'>&#x2716;</span>
                            </div>
                            {/* <div className="flex">
                                <Input className=" mr-5" type="email" />
                                <Input className="mr-5 ml-8" type="text" />
                                <span className='text-xl text-gray-500 mt-2 cursor-pointer'>&#x2716;</span>
                            </div>
                            <div className="flex">
                                <Input className=" mr-5" type="email" />
                                <Input className="mr-5 ml-8" type="text" />
                                <span className='text-xl text-gray-500 mt-2 cursor-pointer'>&#x2716;</span>
                            </div> */}
                            <p className='text-indigo-800 font-medium text-sm mt-3' >
                                <span className="cursor-pointer" onClick={() => setShowMulti(!showMulti)}>
                                    <span className='border border-indigo-800 text-xs mr-1 cus-padding font-medium'>+</span>
                                    Add New
                                    <span className="text-gray-600"> OR </span>
                                    Add Multiple at Once
                                </span>
                            </p>
                            <div className="flex justify-end mt-4">
                                <button className='bg-indigo-800 text-white px-5 py-2 font-semibold rounded' >Send Invitation</button>
                            </div>
                        </Card>
                    </form>
                </Modal>
            </div>
            {/* MULTIPLE EMAIL MODAL  */}
            <div className={`${showMulti ? "block" : "hidden"}`}>
                <Modal >
                    <Card className="bg-white lg:min-w-[850px] lg:min-h-[360px] rounded p-10">
                        <div className='flex justify-between'>
                            <h3 className='text-2xl font-semibold'>Invite Members</h3>
                            <span className='text-xl cursor-pointer' onClick={handleToggle} >&#x2716;</span>
                        </div>
                        <div className="mt-7">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='m-10 mb-5'>
                                <Grid item xs={6} className=''>
                                    <h4 className='text-xl font-semibold text-gray-500'>Email Address</h4>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="flex">
                            <textarea rows="1" className='border px-4 py-4 mb-3 w-full'>Eg. xyz@chetu.com</textarea>
                        </div>

                        <p className='text-indigo-800 font-medium text-sm mt-2'>
                            <span className="cursor-pointer" onClick={() => setShowMulti(!showMulti)}>
                                <span className='border border-indigo-800 text-xs mr-1 cus-padding font-medium'>+</span>
                                Add New
                                <span className="text-gray-600 "> OR </span>
                                Add Multiple at Once
                            </span>
                        </p>
                        <div className="flex justify-end mt-4">
                            <button className='bg-indigo-800 text-white px-5 py-2 font-semibold rounded'>Send Invitation</button>
                        </div>
                    </Card>
                </Modal>
            </div>
        </div>
    )
}
export default PopupForm;