import React, { useState } from 'react'
import Logo from '../../assets/images/IDEARE final.png'
import { Link } from 'react-router-dom'

const Header = () => {
  const [show, setShow] = useState(false)
  const toggleShow = () => {
    setShow(!show)
  }

  return (
    <>
      <header className="header flex justify-between items-center py-3 sticky">
        <div className="logo mx-3">
          <Link to="/"><img src={Logo} alt="" className='w-28' /></Link>
        </div>
        <div className="menu flex items-center">
          <ul className='flex font-medium'>
            <li className='px-2'>About</li>
            <li className='px-2'><Link to="/pricing">Plan&Pricing</Link> </li>
            <li className='px-2'>Services</li>
            <li className='px-2'>Contact Us</li>
          </ul>
          <Link to="/login"><button className='primary-btn w-28 mx-3 h-10'>Login</button></Link>
        </div>
        <div className="hamburger" onClick={toggleShow}>
          <div className="burger burger-1" style={{ transform: show ? 'rotate(45deg)' : 'rotate(0)' }} />
          <div className="burger burger-2" style={{ transform: show ? 'translateX(100%)' : 'translateX(0)', opacity: show ? 0 : 1 }} />
          <div className="burger burger-3" style={{ transform: show ? 'rotate(-45deg)' : 'rotate(0)' }} />
        </div>
      </header>
      <div className="mobile-menu">
        <ul className={`mob-ul ${show ? "mobile-show" : "mobile-hide"}`}>
          <li className='px-2 py-2'>About</li>
          <li className='px-2 py-2'>Plan&Pricing</li>
          <li className='px-2 py-2'>Services</li>
          <li className='px-2 py-2'>Contact Us</li>
          <li className="px-2 py-2">
            <Link to="/login"><button className='primary-btn w-28 h-10'>Login</button></Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Header