import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from './AuthContext'

const Protected = (props) => {
    const {Component}=props
    const navigate=useNavigate()


    useEffect(() => {
      const login = sessionStorage.getItem("isLogin")
      if(!login){
        navigate('/login')
      }
    }, [])

  return (
    <div>
        {Component}
    </div>
  )
}

export default Protected