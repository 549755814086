import React, { useState } from 'react'
import axios from 'axios'
import Card from '../components/UI/Card'
import { useNavigate } from 'react-router-dom'
import {manualUrl} from '../configuration/config'


const ManualMeasurement = () => {
    const [image, setImage] = useState('');
    const [width, setWidth] = useState('');
    const navigate = useNavigate()


    const genrateLayout = async (value) => {
        try {
            const formdata = new FormData();
            formdata.append("total_width", value);
            formdata.append('user_id', sessionStorage.getItem('user_id'));
            const response = await axios.post(manualUrl, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setImage(response?.data?.image_url);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    }
    const handleLayoutSubmit = () => {
        genrateLayout(width)
    }

    const handleBack = () => {
        navigate('/uploadimage')
    }


    return (
        <>
            <div>
                <div className='ml-20 mt-6 cursor-pointer font-medium' onClick={handleBack}>&#129028; Back</div>
                <div className="main-wrapper bg-no-repeat bg-cover relative  bg-center flex justify-center items-center manual-msr">
                    <Card className="my-6 p-5 rounded-2xl md:w-8/12 w-full mx-2 md:mx-0 bg-white">
                        <h3 className='text-3xl pb-1 text-black text-center font-sans mb-2'>Manual Measurement</h3>
                        <div className='flex justify-center'>
                            <input type='number' className='border-2 border-black px-2' onChange={(e) => setWidth(e.target.value)} />
                            <button className='text-black pr-8 pl-8 rounded h-11 border-black border-solid border-2 block' onClick={handleLayoutSubmit}>Generate</button>

                        </div>
                        <h6 className='text-black text-center text-sm pt-1 '>input the width of wall for cabinet planning</h6>
                        <div className="flex justify-center">
                            {image ? (
                                <img src={image} alt='no img' />
                            ) : ""}
                        </div>
                    </Card>
                </div>

            </div>
        </>
    )
}

export default ManualMeasurement