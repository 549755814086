import React from 'react'
import Card from "../components/UI/Card";
import Input from '../components/UI/Input';
import Button from "../components/UI/Button"
import { Link } from "react-router-dom";

const CorporateUser = () => {
    return (
        <section className="main-wrapper h-screen bg-no-repeat bg-cover bg-center banner-grad relative">
            <div className="user-detail md:flex flex-col h-screen justify-center md:ml-11 md:p-0 p-3">
                <Card className='card md:w-6/12 ml-0 p-4 md:p-6'>
                    <p className='font-semibold text-2xl pb-9'>Company Detail</p>
                    <div className="flex flex-wrap justify-between">
                        <div className="cname md:w-6/12 w-full px-3 ">
                            <label htmlFor="name" className='font-medium'>Company Name</label>
                            <Input type="text" name="cname" className="user1" placeholder="Enter your username" />
                        </div>
                        <div className="cnumber md:w-6/12 w-full px-3 ">
                            <label htmlFor="name" className='font-medium'>Contact Number</label>
                            <Input type="text" name="cnumber" className="cname" placeholder="Enter your number" />
                        </div>
                        <div className="email md:w-6/12 w-full px-3 pt-3">
                            <label htmlFor="email" className='font-medium'>Email</label>
                            <Input type="email" name="cnumber" className="cname" placeholder="Enter your email" />
                        </div>
                        <div className="role md:w-6/12 w-full px-3 pt-3">
                            <label htmlFor="role" className='font-medium'>Role</label>
                            <select name="" id="role">
                                <option value="">Sales</option>
                                <option value="">Design</option>
                                <option value="">Project Manager</option>
                                <option value="">Owner</option>
                                <option value="">Administrative Assistant</option>
                                <option value="">Other</option>
                            </select>
                        </div>
                        <div className="seat md:w-6/12 w-full px-3 pt-3">
                            <label htmlFor="name" className='font-medium'>Number of Users/Seats needed</label>
                            <Input type="text" name="seat" className="cname" placeholder='Enter number of user' />
                        </div>
                        <div className="sdate md:w-6/12 w-full px-3 pt-3">
                            <label htmlFor="sdate" className='font-medium'>Start Date of Licence Term</label>
                            <Input type="date" name="sdate" className="sdate" />
                        </div>
                        <div className="check1 w-full pt-3 px-3 font-medium">
                            <Input type="checkbox" name="check" className="check mr-2" />I agree to <Link className='text-blue-600'>terms & conditions.</Link>
                        </div>
                        <div className="next w-full px-3 pt-3">
                            <Button className="primary-btn md:w-96 w-full">Next</Button>
                        </div>
                    </div>
                </Card>

            </div>

        </section>
    )
}

export default CorporateUser