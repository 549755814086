import React from 'react'
import LoaderGif from '../assets/images/loading-animation.gif'
import { useNavigate } from 'react-router-dom'

const PredictPopUp = (props) => {
    const { originalImg, predictedImg, setPredictModal, navigation } = props
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(navigation)
    }

    return (
        <>
            {originalImg === 'undefined' ? <div className='main-overlay backdrop-filter backdrop-blur-sm'>
                <div className='border lg:min-w-[1500px] bg-white lg:min-h-[765px] rounded  p-10'>
                    <div className='flex justify-between mb-10'>
                        <h1 className='text-3xl font-semibold'>Image Annotation Result</h1>
                        <span className='text-xl cursor-pointer' onClick={() => setPredictModal(false)}>&#x2716;</span>
                    </div>
                    <div className='flex justify-center items-center h-[550px]'>

                        <h1 className='text-center'>Loading</h1>
                        <img src={LoaderGif} alt="" style={{ "marginLeft": "-39px" }} />

                    </div>

                </div>
            </div> :
                <div className='main-overlay backdrop-filter backdrop-blur-sm'>
                    <div className='border lg:min-w-[1500px] bg-white lg:min-h-[765px] rounded  p-10'>
                        <div className='flex justify-between mb-5'>
                            <h1 className='text-3xl font-semibold'>Image Annotation Result</h1>
                            <span className='text-xl cursor-pointer' onClick={() => setPredictModal(false)}>&#x2716;</span>
                        </div>
                        <div className='flex justify-center items-center h-[550px]'>
                            <div className="original-img h-full mx-2">
                                <img src={originalImg} width={800} alt="" />
                                <h2 className='text-xl text-center mt-4 font-semibold'>Original Image</h2>
                            </div>
                            <div className=" predicted-img h-full mx-2">
                                <img src={predictedImg} width={800} alt="" />
                                <h2 className='text-xl text-center mt-4 font-semibold'>Annotated Image</h2>
                            </div>
                        </div>
                        <div className="flex justify-end mt-2">
                            <button className='bg-indigo-800 text-white px-5 py-2 font-semibold rounded' onClick={handleNavigate} >Proceed</button>
                        </div>

                    </div>
                </div>}
        </>
    )
}

export default PredictPopUp

