import React from "react";
import Card from "../components/UI/Card";
import Degree from "../assets/images/360-degrees 1.png";
import Virtual from "../assets/images/virtual-tour 1.png";
import Computer from "../assets/images/working-with-computer 1.png";
import Plan from "../assets/images/plan 1.png"
import { Link } from "react-router-dom";
const Landingpage = () => {
  return (
    <>

      {/* Banner section start here */}
      <section className="main-wrapper h-screen bg-no-repeat bg-cover bg-center banner-grad relative">
        <div className="banner-content banner-small text-gray-50 w-4/12 flex flex-col h-screen justify-center ml-10">
          <h1 className="text-gray-50 p-0">
            INTELLIGENT SOFTWARE, EXCEPTIONAL SPACES
          </h1>
          <p className="py-3 text-justify text-base font-medium">
            Welcome to iDeare, where cutting-edge software meets innovative
            design. Our software VeraAI offers a suite of powerful features,
            including virtual exploration, user-friendly interface aided by AI,
            and real-time collaboration. Our software transforms your design
            process, enabling you to create stunningly realistic andfunctional
            designs. With VeraAI, your design potential is limitless.
          </p>
          <button className="button-small border-2 border-white  py-2 px-3 w-2/6 mt-2 font-semibold">
            <Link to="/pricing">GET STARTED</Link>
          </button>
        </div>
      </section>
      {/* Banner section end here */}

      {/* Key Features section start here */}
      <section className="features mt-11">
        <h1 className="text-center">KEY FEATURES</h1>
        <div className="xl:container mx-auto my-10">
          <div className="flex flex-wrap justify-around items-start sm:flex-wrap md:flex-wrap">
            <Card className="card-feature small-100 w-6/12  border-r border-b flex justify-center p-10">
              <div className="flex justify-center flex-col text-center">
                <div className="img-box bg-gray-100 flex drop-shadow justify-center w-40 h-40 p-10 rounded-full mx-auto ">
                  <img src={Virtual} alt="" />
                </div>
                <div className="description pt-4">
                  <h3 className='font-semibold text-xl'>Virtual Exploration</h3>
                  <p className="font-medium pt-2 text-gray-400">
                    Explore design possibilities in a whole new way with our
                    virtual exploration feature that allows you to see your
                    design ideas come to life in real-time.
                  </p>
                </div>
              </div>
            </Card>
            <Card className="card-feature small-100 w-6/12  border-b  flex justify-center p-10">
              <div className="flex justify-center flex-col text-center">
                <div className="img-box flex justify-center drop-shadow bg-gray-100 w-40 h-40 p-10 rounded-full mx-auto  ">
                  <img src={Computer} alt="" />
                </div>
                <div className="description pt-4">
                  <h3 className='font-semibold text-xl'>User-Friendly Interface aided by AI</h3>
                  <p className="font-medium pt-2 text-gray-400">
                    Our software’s user-friendly interface, powered by
                    artificial intelligence, makes designing a breeze, allowing
                    you to focus on your creativity.
                  </p>
                </div>
              </div>
            </Card>
            <Card className="card-feature small-100 w-6/12  border-r  flex justify-center p-10">
              <div className="flex justify-center flex-col text-center">
                <div className="img-box flex justify-center drop-shadow bg-gray-100 w-40 h-40 p-10 rounded-full mx-auto ">
                  <img src={Plan} alt="" />
                </div>
                <div className="description pt-4">
                  <h3 className='font-semibold text-xl'>Real-time collaboration and design changes</h3>
                  <p className="font-medium pt-2 text-gray-400">
                    Collaborate with team members and clients in real-time,
                    making design changes and receiving feedback instantly, with
                    our software’s real-time collaboration feature.
                  </p>
                </div>
              </div>
            </Card>
            <Card className="card-feature small-100 w-6/12   flex justify-center p-10">
              <div className="flex justify-center flex-col text-center">
                <div className="img-box flex justify-center drop-shadow bg-gray-100 w-40 h-40 p-10 rounded-full mx-auto ">
                  <img src={Degree} alt="" />
                </div>
                <div className="description pt-4">
                  <h3 className='font-semibold text-xl'>Virtual Showrooms</h3>
                  <p className="font-medium pt-2 text-gray-400">Virtual Showrooms replicate the physical showroom experience, allowing
                    customers to view and integrate products into their design from home.</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landingpage;
