import React from 'react'
import Card from "../UI/Card";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { forgotVal } from '../../Validation/Validation';
import { useFormik } from 'formik';

function Forgot() {
  const initialValues = {
    username: "",
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: forgotVal,
    onSubmit: (values) => {
      console.log("VALUES", values);
    }
  });
  return (
    <div className="login-wrapper h-screen bg-no-repeat login-grad relative  bg-center flex justify-center items-center">
      <div className="box-2 absolute">
        <div className="box-2-heading text-white">
          <h1 className="kitchen-heading text-white">INTELLIGENT <br />SOFTWARE, <br />EXCEPTIONAL <br />SPACES</h1>
          <div className="down-r box-2-p">
            <p>with help of VERA AI</p>
          </div>
        </div>
      </div>
      <Card className="card z-10">
        <form onSubmit={handleSubmit}>
          <h1>Forgot your password</h1>
          <p>Enter your username and we will send you instructions to reset your password</p>
          <div className="username pt-9">
            <label htmlFor="username" className="font-medium">
              Username
            </label>
            <Input
              type="text"
              name="username"
              className="user1"
              placeholder="Enter your username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="error">
              {errors.username && touched.username && errors.username}
            </span>
          </div>
          <Button className="primary-btn mt-3">Continue</Button>
        </form>
      </Card>
    </div>
  )
}

export default Forgot