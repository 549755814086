import React, { useContext, useState } from "react";
import Card from "../UI/Card";
import Input from "../UI/Input";
import Button from "../UI/Button";
import Image1 from "../../assets/images/google-icon.png";
import Image2 from "../../assets/images/microsoft.png";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { loginVal } from "../../Validation/Validation";
import { useFormik } from "formik";
import { auth, signInWithGooglePopup } from "../../utils/firebase.utils";
import { signInWithEmailAndPassword } from "firebase/auth";
import Avatar from '../../assets/images/user-avatar.png'
import { AuthContext } from "./AuthContext";

const Login = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [passwordType, setPasswordType] = useState("password");
  const initialValues = {
    username: "",
    password: "",
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: loginVal,
    onSubmit: (values) => {
      login()
    }
  });
  const { loginContext } = useContext(AuthContext);

  const handleLogin = ( username,avatar) => {
    loginContext( username,avatar);
  };

  async function login(){
    await signInWithEmailAndPassword(auth, values.username, values.password).then((userCredential)=>{
        navigate('/designassistant')
        // we need to get the google user id here
        sessionStorage.setItem("user_name", userCredential.user.displayName);
        sessionStorage.setItem('email', userCredential.user.email);
        sessionStorage.setItem('user_id', userCredential.user.uid );
        sessionStorage.setItem("avatar", Avatar);
        sessionStorage.setItem("isLogin", true);
        handleLogin("Ideare User",Avatar);
    }).catch((error)=>{
        if(error.code === "auth/invalid-credential"){
          setError("Invalid login credentials")
        }
        else{
          console.log(error)
        }
    })
}

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    const userName = response._tokenResponse.firstName;
    const userAvatar = response._tokenResponse.photoUrl
    sessionStorage.setItem("user_name", response.user.displayName);
    sessionStorage.setItem('email', response.user.email);
    sessionStorage.setItem('user_id', response.user.uid );
    sessionStorage.setItem("avatar", response.user.photoURL);
    sessionStorage.setItem("isLogin", true)
    handleLogin(userName,userAvatar);

    navigate('/designassistant')
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  return (
    <div className="login-wrapper h-screen bg-no-repeat login-grad relative  bg-center flex justify-center items-center">
      <div className="box-2 absolute">
        <div className="box-2-heading text-white">
          <h1 className="kitchen-heading text-white">INTELLIGENT <br />SOFTWARE, <br />EXCEPTIONAL <br />SPACES</h1>
          <div className="down-r box-2-p">
            <p>with help of VERA AI</p>
          </div>
        </div>
      </div>
      <Card className="card z-10 LoginModal">
        <form onSubmit={handleSubmit}>
          <h1>Welcome Back</h1>
          <p>Please enter your login credentials to continue.</p>
          <span className="f-error mt-5">
            {error}
          </span>
          <div className="username pt-9">
            <label htmlFor="username" className="font-medium">
              Username
            </label>
            <Input
              type="text"
              name="username"
              className="user1"
              placeholder="Enter your username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="error">
              {errors.username && touched.username && errors.username}
            </span>
          </div>
          <div className="password py-6 relative">
            <label htmlFor="password" className="font-medium">
              Password
            </label>
            <Input
              type={passwordType}
              name="password"
              className="pass"
              placeholder="Enter your password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="error">
              {errors.password && touched.password && errors.password}
            </span>
            <div onClick={togglePassword} className="icon">
              {passwordType === "password" ? <VisibilityOffIcon className="svgicon" /> : <VisibilityIcon className="svgicon" />}
            </div>
          </div>
          <div className="flex justify-between pb-6">
            <div className="check1">
              <Input type="checkbox" name="check" className="check " />
              <label htmlFor="" className="font-medium ml-1">
                Remember Me
              </label>
            </div>
            <Link to="/forgot" className="font-medium text-blue-800">
              Forgot Password
            </Link>
          </div>
          <Button className="primary-btn" type='submit'>Login</Button>
          <div className="or for relative my-6">
            <p className="text-center">OR</p>
          </div>
          <div className="icons flex justify-center">
            <div className="google-icon border p-3 rounded-3xl cursor-pointer" onClick={logGoogleUser}>
              <img src={Image1} alt="" srcSet="" />
            </div>
            <div className="microsoft-icon border p-3 rounded-3xl ml-2 cursor-pointer">
              <img src={Image2} alt="" srcSet="" />
            </div>
          </div>
          <p className="text-center pt-5">
            Don't have an account? <Link to="/signup" className="font-medium text-blue-800">Sign Up</Link>
          </p>
        </form>
      </Card>
    </div>
  );
};

export default Login;
