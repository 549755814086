import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import EditIcon from '../../assets/images/edit-icon.svg'
import FullStarIcon from '../../assets/images/full-star-icon.svg'
import StarIcon from '../../assets/images/star-icon.svg'
import UploadIcon from '../../assets/images/upload-icon.svg'
import ArrowIcon from '../../assets/images/arrow-icon.svg'
import axios from 'axios';
import Card from '../UI/Card'
import Button from '../UI/Button'
import PredictPopUp from '../../pages/PredictPopUp';
import { predictUrl, uploadUrl } from '../../configuration/config';

const LeftSidebar = (props) => {
    const {kitchenImg, toggle, setToggle, modalNavigation} = props

    const navigate = useNavigate();
    const inputFileRef = useRef(null); // Create a ref for the file input
    const [favorite, setFavorite] = useState(false)
    const [uploadImageUrl, setUploadImageUrl] = useState('');
    const [getImageUrls, setGetImageUrl] = useState('');
    const [predictModal, setPredictModal] = useState(false)

    const handleFavorite = () => {
        setFavorite(!favorite)
    }

    // UPLOADING API **********
    const uploadImage = async (value) => {
        try {
            const formdata = new FormData();
            formdata.append("image", value);
            const response = await axios.post(uploadUrl, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUploadImageUrl(response);
            setPredictModal(true)
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    // FETCH API ***********
    const fetchImage = async () => {
        try {
            const response = await axios.post(predictUrl);
            setGetImageUrl({ original: response.data.original_image_url, predicted: response.data.predicted_image_url });
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    useEffect(() => {
        if (uploadImageUrl !== '') {
            fetchImage();
        }
    }, [uploadImageUrl]);

    // For File Upload Functionality 
    const handleUploadButtonClick = () => {
        // Simulate a click on the file input
        inputFileRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        uploadImage(selectedFile);
    };
    const handleNavigate = (demo) => {
        navigate(demo)
    }

    return (
        <>
            <div className="left-wrapper flex items-center">
                <div className={`grad-color left-sidebar flex ${toggle ? "left-inactive" : "left-active"} `}>
                    <div className="grad-color left-sidebar1 px-2 py-8">
                        <div className="border flex flex-col text-white justify-center items-center text-center px-1 py-3 mb-4 cursor-pointer">
                            <img className='mb-3' src={EditIcon} alt="" width={22} />
                            <h5 className='text-sm'>Edit Design</h5>
                        </div>
                        <div className="border flex flex-col text-white justify-center items-center text-center px-1 py-3 mb-4 cursor-pointer">
                            <img className='mb-3' src={EditIcon} alt="" width={22} />
                            <h5 className='text-sm'>My Projects</h5>
                        </div>
                        <div className="border flex flex-col text-white justify-center items-center text-center px-1 py-3 cursor-pointer" onClick={handleFavorite}>
                            <img className='mb-3' src={favorite ? FullStarIcon : StarIcon} alt="" width={22} />
                            <h5 className='text-sm'>Favorites</h5>
                        </div>
                    </div>
                    <div className='flex flex-col px-4 py-8'>
                        <div className='flex text-white cursor-pointer ml-2' onClick={handleUploadButtonClick}>
                            <img src={UploadIcon} alt="" width={15} />
                            <h5 className='ml-2 text-sm'>Upload New Photo</h5>
                            {/* Invisible file input */}
                            <input
                                type="file"
                                id="file"
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <Card className="border rounded mt-7">
                            <img src={kitchenImg} width={190} alt="" />
                        </Card>
                        <Button className="border-2 text-white py-3 mt-8 text-lg" onClick={() => handleNavigate("/selectdesign")}>
                            Change Design
                        </Button>
                        <Button className="border-2 text-white py-3 mt-8 text-lg">
                            Sync with VR
                        </Button>
                        <Button className="border-2 text-white py-3 mt-8 text-lg" onClick={() => handleNavigate("/adminscopeofwork")}>
                            Scope of Work
                        </Button>
                        <Button className="border-2 text-white py-3 mt-8 text-lg" onClick={() => handleNavigate("/cabinet")}>
                            Demo 1
                        </Button>
                        <Button className="border-2 text-white py-3 mt-8 text-lg" onClick={() => handleNavigate("/cabinet2")}>
                            Demo 2
                        </Button>
                        <Button className="border-2 text-white py-3 mt-8 text-lg" onClick={() => handleNavigate("/cabinet3")}>
                            Demo 3
                        </Button>
                    </div>
                </div>
                <div className="left-toggle" onClick={() => setToggle(!toggle)} >
                    <div className='grad-color py-20 px-4 rounded-r-lg cursor-pointer'>
                        <img className={`${toggle ? "rotate-180" : ""}`} src={ArrowIcon} alt="" />
                    </div>
                </div>
            </div>

            {
                predictModal ?
                    <PredictPopUp originalImg={`${getImageUrls?.original}`.replace("http", "https")} predictedImg={`${getImageUrls?.predicted}`.replace("http", "https")} setPredictModal={setPredictModal} navigation={modalNavigation} /> : ""
            }
        </>
    )
}

export default LeftSidebar