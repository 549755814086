
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8iBiijhI89r3Oi8ZwXzD8sG8jqWEGdjs",
  authDomain: "veraai-4b23a.firebaseapp.com",
  projectId: "veraai-4b23a",
  storageBucket: "veraai-4b23a.appspot.com",
  messagingSenderId: "664450896898",
  appId: "1:664450896898:web:02b914a29c627e2e1ec31b",
  measurementId: "G-DNNGRB0T1X"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);