import React, { useEffect, useState } from "react";
// import React from 'react'
import Logo from '../assets/images/IDEARE final.png'
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";


const ScopeOfWork = (props) => {
    const currentDate = new Date().toLocaleDateString();
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [totalAmount, setTotalAmount] = useState(0);
    let navigate = useNavigate();
    let [data, setData] = useState(1);
    let [data2, setData2] = useState(1);
    let [data3, setData3] = useState(1);
    let [data4, setData4] = useState(1);
    let [data5, setData5] = useState(1);
    let [data6, setData6] = useState(1);
    let [data7, setData7] = useState(1);
    let [data8, setData8] = useState(1);
    let [data9, setData9] = useState(1);



    const increase = (val, fun) => {
        fun(val + 1);
    }
    const decrease = (val, fun) => {
        if (val > 1)
            fun(val - 1);
    }

    function openlist() {
        setTimeout(() => {
            navigate("/scopeofwork");
        }, 1000);
    }

    const formik = useFormik({
        initialValues: {
            obj: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj2: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj3: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj4: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj5: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj6: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj7: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj8: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
            obj9: {
                name: "",
                quatity: "",
                rate: "",
                amount: "",
                show: true,
            },
        },
        onSubmit: (values) => {
            props.setformdata(values);
        },
    });

    // Update totalAmount whenever amount values change
    useEffect(() => {
        let sum = 0;
        for (const objKey in formik.values) {
            if (formik.values[objKey]?.amount) {
                sum += parseFloat(formik.values[objKey].amount) || 0;
            }
        }
        setTotalAmount(sum);
    }, [formik])

    return (
        <>
            <div className="scope-container py-10 flex justify-center custom-bill-banner bg-no-repeat bg-cover bg-center">
                <div className="w-[1624px] border px-10 py-12 bg-white">
                    <div className="flex justify-between items-center mb-9">
                        <img src={Logo} alt="" className='w-32' />
                        <h5 className='text-4xl font-medium'>Bill of Materials</h5>
                    </div>
                    <hr className='hr-scope' />
                    <div className="scope-date font-semibold my-5">
                        Date: <span className='font-normal'>{currentDate}</span>
                    </div>
                    <form action="" onSubmit={formik.handleSubmit}>
                        <div className="table-scope">
                            <table class="table-auto w-full">
                                <thead className='border bg-green-50'>
                                    <tr>
                                        <th className='text-left py-5 px-10 w-[450px]'>Material</th>
                                        <th className='text-center py-5 px-3'>Quantity</th>
                                        <th className='text-center py-5 px-3'>Rate</th>
                                        <th className='text-center py-5 px-3'>Amount</th>
                                        <th className='text-center py-5 px-3'>Show/Hide</th>
                                    </tr>
                                </thead>
                                <tbody className='border'>

                                    <tr className='border'>
                                        <td className=' py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data, setData)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data, setData)} type="button">
                                                +
                                            </button>
                                            <input
                                                type="number"
                                                name="obj.quatity"
                                                value={(formik.values.obj.quatity = data)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj.amount"
                                                value={(formik.values.obj.amount = data * formik.values.obj.rate)}
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-3 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />

                                        </td>
                                    </tr>
                                    <tr className='border'>
                                        <td className=' py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj2.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data2, setData2)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data2}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data2, setData2)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj2.quatity"
                                                value={(formik.values.obj2.quatity = data2)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj2.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj2.amount2"
                                                value={
                                                    (formik.values.obj2.amount = data2 * formik.values.obj2.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-5 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj2.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-medium py-3 px-10'>Backsplash Material</td>
                                    </tr>
                                    <tr className='border'>
                                        <td className=' py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj3.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data3, setData3)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data3}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data3, setData3)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj3.quatity"
                                                value={(formik.values.obj3.quatity = data3)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj3.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj3.amount2"
                                                value={
                                                    (formik.values.obj3.amount = data3 * formik.values.obj3.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-5 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj3.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr className='border'>
                                        <td className='font-medium py-3 px-10'>Flooring Material</td>
                                    </tr>
                                    <tr >
                                        <td className=' py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj4.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data4, setData4)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data4}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data4, setData4)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj4.quatity"
                                                value={(formik.values.obj4.quatity = data4)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj4.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj4.amount2"
                                                value={
                                                    (formik.values.obj4.amount = data4 * formik.values.obj4.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-5 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj4.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className=' pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj5.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data5, setData5)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data5}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data5, setData5)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj5.quatity"
                                                value={(formik.values.obj5.quatity = data5)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj5.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj5.amount2"
                                                value={
                                                    (formik.values.obj5.amount = data5 * formik.values.obj5.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj5.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className=' pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj6.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data6, setData6)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data6}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data6, setData6)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj6.quatity"
                                                value={(formik.values.obj6.quatity = data6)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj6.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj6.amount2"
                                                value={
                                                    (formik.values.obj6.amount = data6 * formik.values.obj6.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj6.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className=' pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Material Name"
                                                name="obj7.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data7, setData7)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data7}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data7, setData7)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj7.quatity"
                                                value={(formik.values.obj7.quatity = data6)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj7.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj7.amount2"
                                                value={
                                                    (formik.values.obj7.amount = data7 * formik.values.obj7.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj7.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table-auto w-full my-10">
                                <thead className='border bg-green-50'>
                                    <tr>
                                        <th className='text-left py-5 px-10 w-[450px]'>Work Description</th>
                                        <th className='text-center py-5 px-3'>Hours</th>
                                        <th className='text-center py-5 px-3'>Rate</th>
                                        <th className='text-center py-5 px-3'>Amount</th>
                                        <th className='text-center py-5 px-3'>Show/Hide</th>
                                    </tr>
                                </thead>
                                <tbody className='border'>
                                    <tr className='border'>
                                        <td className='py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Work Description"
                                                name="obj8.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data8, setData8)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data8}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data8, setData8)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj8.quatity"
                                                value={(formik.values.obj8.quatity = data6)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj8.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj8.amount2"
                                                value={
                                                    (formik.values.obj8.amount = data8 * formik.values.obj8.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-3 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj8.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>

                                    <tr className='border'>
                                        <td className='py-5 pb-2 px-10 font-medium'>
                                            <input
                                                type="text"
                                                placeholder="Work Description"
                                                name="obj9.name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 flex justify-center items-center font-medium'>
                                            <button className="border px-3 py-2 font-bold" onClick={() => decrease(data9, setData9)} type="button">
                                                -
                                            </button>
                                            <span className="border px-3 py-2">{data9}</span>
                                            <button className="border px-3 py-2 font-bold" onClick={() => increase(data9, setData9)} type="button">
                                                +
                                            </button>

                                            <input
                                                type="number"
                                                name="obj9.quatity"
                                                value={(formik.values.obj9.quatity = data6)}
                                                readOnly
                                                hidden
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border rate-input"
                                                type="number"
                                                placeholder="Rate"
                                                name="obj9.rate"
                                                value={formik.values.rate}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                        <td className='text-center py-5 pb-2 px-3 font-medium'>
                                            <input
                                                className="border amt-input"
                                                type="number"
                                                placeholder="Amount"
                                                name="obj9.amount2"
                                                value={
                                                    (formik.values.obj9.amount = data9 * formik.values.obj9.rate)
                                                }
                                                readOnly
                                            />
                                        </td>
                                        <td className="text-center py-3 pb-2 px-3">
                                            <Switch {...label} defaultChecked
                                                name="obj9.show"
                                                value={formik.values.show}
                                                onChange={formik.handleChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr className='border'>
                                        <td className=' py-3 px-10'></td>
                                        <td className='text-center py-3 px-3'></td>
                                        <td className='text-center py-3 px-3 font-semibold'>Sub Total:</td>
                                        <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount}</td>
                                    </tr>
                                    <tr className='border'>
                                        <td className=' py-3 px-10'></td>
                                        <td className='text-center py-3 px-3'></td>
                                        <td className='text-center py-3 px-3 font-semibold'>Tax:</td>
                                        <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount * 10 / 100}</td>
                                    </tr>
                                    <tr className='border'>
                                        <td className=' py-3 px-10'></td>
                                        <td className='text-center py-3 px-3'></td>
                                        <td className='text-center py-3 px-3 font-semibold'>Total:</td>
                                        <td className='text-center py-3 px-3 font-semibold'>$ {totalAmount + (totalAmount * 10 / 100)}</td>
                                    </tr>

                                </tbody>
                            </table>
                            <div className="btn-grp flex justify-end">
                                
                                <input className="border-2 px-5 py-3 text-white text-lg bg-indigo-600 rounded font-medium mx-5" type="submit" onClick={openlist} value="Generate Bill" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ScopeOfWork