import React from 'react'
import Card from '../components/UI/Card'
import { useNavigate } from 'react-router-dom';

function DesignAssistant() {
    const navigate = useNavigate()
    const newProject=()=>{
        navigate('/customerinformation')
    }
    return (
        <div className="main-wrapper h-screen bg-no-repeat bg-cover banner-grad relative  bg-center flex justify-center items-center" >
            <Card className="box-1">
                <h1 className='box-h1'>Hi I’m VeraAI Your</h1>
                <p className='box-p'>Personal Design Assistant</p>
                <h6 className='box-h6 lets-top'>Let’s Begin!</h6>
                <div style={{ display: 'flex' }}>
                    <button className='box-btn'>EXISTING PROJECT</button>
                    <button className='box-btn ml-2' onClick={newProject} >NEW PROJECT</button>
                </div>
            </Card>
        </div>
    )
}

export default DesignAssistant